export const useLandingPage = () => {
  const state = useState<LandingPage>("landing-page", () => null);

  const getDefaultPersonalisation = () => state.value.personalisations?.find((personalisation) => personalisation.default_version);

  const getPersonalisation = (hashid: string) => state.value.personalisations?.find((personalisation) => personalisation.hashid === hashid);

  const updateBlock = (block: Block) => {
    const index = state.value?.collection.blocks.findIndex((b) => b.id === block.id);

    if (index !== -1) {
      state.value.collection.blocks[index] = block;
    }
  };

  const updatePersonalisation = (personalisation: CollectionPersonalisation) => {
    const index = state.value?.personalisations?.findIndex((p) => p.hashid === personalisation.hashid) ?? -1;

    if (state.value.personalisations && index !== -1) {
      state.value.personalisations[index] = personalisation;
    }
  };

  return {
    data: state,
    updateBlock,
    getDefaultPersonalisation,
    getPersonalisation,
    updatePersonalisation,
  };
};

export default useLandingPage;
